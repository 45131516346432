import { get, set } from 'solid-utils/access';
import { For, createMemo, mergeProps, createSignal, Show, untrack } from 'solid-js';
import type { Signal } from 'solid-js';

import ChevronRight from 'icons/ChevronRight.svg';

export default function DatepickerStandalone(_props: {
  value: Signal<Date | null>;
  today?: Date;
  timezoneOffset?: number;
  weekStartDay?: number;
  onClose?: VoidFunction;
  locale?: Intl.Locale;
  weekDaysType?: string;
  weekNamesClass?: string;
  monthSelectorFormat?: string;
  datePickerWrapperClass?: string;
  daysActivePrimaryBtnClass?: string;
  daysBtnClass?: string;
}) {
  const props = mergeProps({
    weekStartDay: 1,
  }, _props);

  const getToday = () => props.today ?? new Date();

  const selectedDate = () => get(props.value) ?? getToday();

  const displayMonthDate = createSignal(selectedDate());

  const getDisplayMonth = () => get(displayMonthDate).getMonth();

  const setDisplayMonth = (delta: number) => {
    const date = new Date(get(displayMonthDate));
    date.setMonth(getDisplayMonth() + delta, 1);

    set(displayMonthDate, date);
  };

  const currentMonthPage = createMemo(() => getMonthPage(
    getDisplayMonth(),
    get(displayMonthDate).getFullYear(),
  ));

  const language = () => new Intl.Locale(props.locale ?? navigator.language).language;

  const displayWeekNames = createMemo(() => new Intl.DateTimeFormat(
    language(),
    { weekday: 'short' },
  ));

  const displayMonthNames = createMemo(() => new Intl.DateTimeFormat(
    language(),
    { month: 'short', year: 'numeric' },
  ));

  const displayNumber = createMemo(() => new Intl.NumberFormat(language()));

  const firstWeek = createMemo(() => {
    // track
    props.weekStartDay;

    return untrack(() => currentMonthPage().slice(0, 7));
  });

  const isDateEqual = (date: Date | null, relativeTo: Date = getToday()) => {
    return !!date && (
      date.getDate() === relativeTo.getDate()
      && date.getMonth() === relativeTo.getMonth()
      && date.getFullYear() === relativeTo.getFullYear()
    );
  };

  return <div class="datepicker">
    <div class="=month-controls flex items-center px-4 rtl:flex-row-reverse">
      <div class="=previous-month ml--2 p-4 cursor-pointer" role="button" onClick={() => setDisplayMonth(-1)}>
        <ChevronRight class="= rotate-180 fill-tg_hint" />
      </div>
      <div class="=current-month flex-grow text-center capitalize app-text-subheadline font-600!">
        {displayMonthNames().format(get(displayMonthDate))}
      </div>
      <div class="=next-month mr--2 p-4 cursor-pointer" role="button" onClick={() => setDisplayMonth(+1)}>
        <ChevronRight class="= fill-tg_hint" />
      </div>
    </div>

    <div class="=dates p-4 pt-0 grid grid-rows-7 grid-cols-7 items-center justify-center">
      <For each={firstWeek()}>
        {cell =>
          <div class="=weekday-cell inline-flex items-center justify-center app-text-subheadline-emphasized capitalize">
            {displayWeekNames().format(cell.date).slice(0, 2)}
          </div>
        }
      </For>
      <For each={currentMonthPage()}>
        {cell =>
          <div role="button" onClick={() => {
            if (!cell.isCurrentMonth) {
              setDisplayMonth(cell.month - getDisplayMonth());
            }
            set(props.value, cell.date);
          }}
            class="=date-cell
              relative inline-flex items-center justify-center cursor-pointer
              aspect-ratio-1/1 min-w-9 min-h-9 hover:bg-tg_bg_secondary
              app-text-subheadline rounded-3 app-transition-background
            "
            classList={{
              '= c-tg_hint': !cell.isCurrentMonth,
              '=current-date bg-tg_button! c-tg_button_text!': cell.isCurrentMonth && isDateEqual(get(props.value), cell.date),
            }}
          >
            <span class="=date text-center w-full">{displayNumber().format(cell.day)}</span>
            <Show when={cell.isCurrentMonth && isDateEqual(cell.date)}>
              <div class="=current-date-underline ml-[-1px] absolute w-40% h-0.75 bottom-1.5 rounded-full bg-tg_button"></div>
            </Show>
          </div>
        }
      </For>
    </div>
  </div>;

  function getMonthLastDate(year: number, month: number) {
    // adding a month since we get the last date of the month
    // by getting the 0-th date of the next month
    return new Date(year, month + 1, 0);
  }

  function getMonthFirstDate(year: number, month: number) {
    return new Date(year, month, 1);
  }

  function getMonthPage(month: number, year: number) {
    const firstDate = getMonthFirstDate(year, month);
    const lastDate = getMonthLastDate(year, month);
    const amount = lastDate.getDate();

    const prevMonthDates = (() => {
      // offset weekday index by start of the week
      const firstWeekDay = ((firstDate.getDay() + 7 - props.weekStartDay) % 7);
      const prevMonthLastDate = getMonthLastDate(year, month - 1).getDate();

      return getDateArray(month - 1, year, firstWeekDay, prevMonthLastDate);
    })();


    const [nextMonthDates, nextMonthFirstEndOfWeek] = (() => {
      // offset weekday index by start of the week
      const lastWeekDay = ((lastDate.getDay() + 7 - props.weekStartDay) % 7);
      const nextMonthFirstEndOfWeek = 6 - lastWeekDay;

      return [getDateArray(month + 1, year, nextMonthFirstEndOfWeek, nextMonthFirstEndOfWeek), nextMonthFirstEndOfWeek] as const;
    })();

    const currentMonthDates = getDateArray(month, year, amount);

    const datesPage = prevMonthDates.concat(currentMonthDates).concat(nextMonthDates);

    // Enough dates to fill a calendar screen of 6 rows and 7 cols
    if (datesPage.length === 42) {
      return datesPage;
    }

    const delta = 42 - datesPage.length;

    // If not enough dates to fill the screen, add the next week
    return datesPage.concat(getDateArray(month + 1, year, 7, nextMonthFirstEndOfWeek + delta)).slice(0, 42);
  }

  function getDateArray(month: number, year: number, amount: number, end?: number) {
    return new Array(amount).fill(0).map<DisplayDate>((_, i) => {
      const day = ((end ?? amount) - amount) + i + 1;

      return {
        day,
        date: new Date(year, month, day),
        isCurrentMonth: !end,
        month,
      };
    });
  }

  interface DisplayDate {
    day: number;
    date: Date;
    isCurrentMonth: boolean;
    month: number;
  }
}
